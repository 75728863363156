.App {
  text-align: center;
}

.map-container {
  height: calc(100vh - 48px);
  position: absolute !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --main-color: #164e63;
  --main-color-ball: #0e7490;
  --main-color-hover: #0e7490;
  --track-color: #888;
  /* Default mark color*/
  --mark-color: #aaaaaa00;
}

.customSlider {
  height: 16px;
}

.customSlider-track {
  /* Top value to align your track to the center of your thumb */
  top: 6px;
  /* thickness of the track */
  height: 4px;
  /* default color of your track */
  background: var(--track-color);
}

.customSlider-track.customSlider-track-0 {
  /* color of the track before the thumb */
  background: var(--main-color);
}
.customSlider-thumb {
  cursor: pointer;
  /*color for the thumb */
  background: var(--main-color-ball);
  /* shape of the thumb: circle */
  width: 16px;
  height: 16px;
  border-radius: 100%;
  /* remove default outline when selected */
  outline: none;
}

.customSlider-thumb:hover {
  box-shadow: 0 0 0 8px var(--main-color-hover);
}

.customSlider-mark {
  cursor: pointer;
  top: 4px;
  width: 1px;
  height: 8px;
  background-color: var(--mark-color);
}

.map-overlay {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  font-family: Arial, sans-serif;
  overflow: auto;
}
#crop-identifier {
  width: 200px;
}
.legend-key {
  display: inline-block;
  border-radius: 20%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: 0 0 16px
    var(--amplify-colors-overlay-10);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-xl)
    var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-tabs-item-active-border-color: var(
    --amplify-colors-neutral-100
  );
}
